html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}
*{
  scroll-behavior: smooth;
}
body {
  width: 100%;
  height: 100%;
  font-family: 'Montserrat', sans-serif;;
}

.banner-info{
  background-color: rgba(62, 63, 68);
}

.label-file{
  border: double 2px transparent;
  border-radius: 10px;
  background-image: linear-gradient(white, white), 
                    linear-gradient(to right,  #52BBAF, #CDCC32);
  background-origin: border-box;
  background-clip: padding-box, border-box;
}
.label-file i{
  color: #ccc;
}
.label-file span{
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  padding: 0 5px;
}
/* .label-file:hover span {
  overflow: visible;
  position: relative;
  background-color: white;
  box-shadow: 0 0 4px 0 black;
  border-radius: 1px;
  width: auto;
} */

.label-file i:hover{
  color: #52BBAF;
}
.border-input{
  padding: 0.5rem !important;
  border: double 2px transparent !important; 
  border-radius: 10px !important;
  background-image: linear-gradient(white, white), 
                    linear-gradient(to right,  #52BBAF, #CDCC32) !important;
  background-origin: border-box;
  background-clip: padding-box, border-box;
}
.border-input:focus{
  border-color: #5db1a7;
}
.btn-form{
  background: linear-gradient(to right, #52BBAF, #CDCC32) !important;
}
.btn-form:hover{
  opacity: 0.8;
}
.header-form{
  /* clip-path:ellipse(65% 70% at 46% 30%); */
  position: absolute;
  top: 0%;
  z-index: 2;
  width: 100%;
}
.modal{
  max-width: 52rem;
}
.close-modal{
  z-index: 3;
}
.titulo-form{
  background-image:linear-gradient(to right,  #52BBAF, #CDCC32);
  -webkit-background-clip: text;
  color: transparent; 
}
.container-modal{
  background-color: rgba(0, 0, 0, 0.8);
}
.vacante:hover{
  cursor: pointer;
  transform: scale(1.02);
  transition: all 0.5s ease;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
}

.icon-vacante, .desc-vacante{
  pointer-events: none;
  color: #fff;
}

/* Carrusel */
.swiper {
  max-width: 1200px;
  width: 100%;
  height: fit-content;
  padding: 50px 0px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.comillas{
  color: #de9f27;
  font-weight: bold;
  font-size:25px;
  margin: 5px;
}
.swiper-button-next,.swiper-button-prev{
  color: #fff !important;
}
.borde-gradiente{
  border: double 2px transparent;
  border-width: 8px;
  padding: 8px;
  background-image: linear-gradient(#182B31, #182B31), 
                    linear-gradient(to right,  #52BBAF, #CDCC32);
  background-origin: border-box;
  background-clip: padding-box, border-box;
}
.swiper-pagination .swiper-pagination-bullet{
  background-color: #fff;
  opacity: 0.8;
  width: 30px;
  border-radius: 15px;
} 
.swiper-pagination .swiper-pagination-bullet-active{
  background-color: #de9f27;
  opacity: 1;
}

.slider {
  overflow-x: scroll;
}

.slider::-webkit-scrollbar {
  display: none;
}

.card {
  background-color: #F4961B;
  margin: 10px;
  border-radius: 4px;
  cursor: pointer;
}

#vacantes{
  background: #182B31;
}

.rounded-full i{
  font-size: 30px;
}
.botones-vacantes button{
  background-color: #fff;
}
.link-vacantes i{
color: #fff;
font-size: 10px;
margin-left: 8px;
}


.link-vacantes:hover{
  color: #de9f27;
}

/* Footer */
  .iconos-footer i{
    color: #000;
    font-size: 2.2rem;
  }

  .col2{
    clip-path: polygon(28% 0, 100% 0, 100% 100%, 0% 100%);
  }

/* figura gradiente verde inicio */
  .figura-gradiente{
    clip-path: polygon(100% 0, 0% 100%, 100% 100%);
  }

/* Fin Footer */

.nombre-empresa{
  margin-top: 25px;
  margin-left: -65px;
}

.active{
  background-color: #F4961B;
  font-weight: bold;
}

/* .section_active{
  min-height: 100vh;
   display: flex;
   justify-content: center;
   align-items: center; 
} */


/* Nosotros */
.title-nosotros{
  color: #de9f27;
}

.render{
  image-rendering: smooth;
}
.logos-desarrollos{
  image-rendering: pixelated;
}

.page-vacante .card{
  background: #fff;
  filter: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));
  /* padding: 15px; */
}

.page-vacante .desc-vacante p{
  color: #000;
}
.page-vacante .desc-vacante i{
  color: #000;
}
.page-vacante .icon-vacante{
  background-color: #F4961B;
}

/* Menu Pagina Vacantes */
/* .menu-vacantes header 
.menu-enlaces a:not(:first-child){
  display: none;
} */
input:disabled{
  color: #a7a7a7;
}
.invalid{
  animation:shake 300ms;
  background-image: linear-gradient(white, white), 
                    linear-gradient(to right,  rgb(254, 88, 88), rgb(254, 88, 88)) !important;
}
@keyframes shake {
  25% {
    transform: translateX(4px);
  }
  50% {
    transform: translateX(-4px);
  }
  75% {
    transform: translateX(4px);
  }
}

/* Alertas */
.container-alert {
  position: fixed;
  display: flex;
  right: 0%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  width: 30vw;
  z-index: 100;
  pointer-events: none;
}

.alert {
  margin: 10px;
  display: none;
  position: relative;
  /* top: 45px; */
  right: -450px;
  width: 80%;
  padding: 5px;
  color: #fff;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-left: 12px solid #505050;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: right 0.5s ease;
  pointer-events: all;
}

.alert .message {
  line-height: 35px;
  font-size: 14px;
  font-weight: 450;
  padding-left: 5px;
  color: #505050;
}

.alert span {
  position: absolute;
  top: 5px;
  right: 10px;
  color: #505050;
  transition: transform 0.2s ease;
}

.alert span:hover {
  cursor: pointer;
  transform: scale(1.2);
}

.alert.Success {
  background-color: #fff;
  border-left-color: #3CCE5D;
  color: #000;
}

.alert.Success span{
  color: #73C084;
}

.alert.Warning {
  background-color: #cccf21;
  border-left-color: #c1c350;
  color: #707006;
}

.alert.Warning span{
  color: #959703;
}

.alert.Danger {
  background-color: white;
  border-left-color: #c73a3a;
  color: black;
}

.alert.Danger span{
  color: #971c1c;
}


/* show message pop up */
.alert.show {
  /* top: 100px; */
  /* top: 45px; */
  right: 10px;
}

.alert.closed {
  opacity: 0;
}

.title-alert.Enviado{
  font-weight: bold;
  color: #73C084;
  font-size: 20px;
}
.title-alert.Error{ 
  font-weight: bold;
  color: #971c1c;
  font-size: 20px;
}

.button-wiggle {
  animation: wiggle 4s 2s infinite;
}

@keyframes wiggle {
	5%,
	50% {
		transform: scale(1);
	}
	10% {
		transform: scale(0.9);
	}
	15% {
		transform: scale(1.15);
	}
	20% {
		transform: scale(1.15) rotate(-5deg);
	}
	25% {
		transform: scale(1.15) rotate(5deg);
	}
	30% {
		transform: scale(1.15) rotate(-3deg);
	}
	35% {
		transform: scale(1.15) rotate(2deg);
	}
	40% {
		transform: scale(1.15) rotate(0);
	}
}

/* Loader */
.container-loader{
  width: 100%;
  height: 100vh;
  background-color:#111827;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5rem;

}
.spinner{
  height:151px;
  width:150px;
  border-radius:50%;
  position:relative;
  background:linear-gradient(45deg,#52BBAF,#CDCC32);
  animation: animate 0.5s linear infinite;
 }
 .spinner::before{
  content:"";
  height:120px;
  width:120px;
  background-color:#111827;
  position:absolute;
  top:15px;
  left:15px;
  border-radius:50%;
 }
 @keyframes animate{
  to{
    transform:
 rotate(360deg);
  }
  }
@media(max-width:768px){
  .col2{
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }
 }

 @media (min-width:300px) and (max-width:959px){
  .swiper-button-next, .swiper-button-prev{
    display: none !important;
  } 
 }

 .footer-vacantes .footer{
  border-top: 1px solid #dfe6e9;
 }
